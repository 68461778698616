import { BigNumber } from '@ethersproject/bignumber';
import { abis, claims } from '@manifoldxyz/claim-contracts';

const {
  CLAIM_EXTENSION_721_V1_MAINNET,
  CLAIM_EXTENSION_1155_V1_MAINNET,
  CLAIM_EXTENSION_721_V2_MAINNET,
  CLAIM_EXTENSION_1155_V2_MAINNET,
  CLAIM_EXTENSION_721_V3_MAINNET,
  CLAIM_EXTENSION_1155_V3_MAINNET,
  CLAIM_EXTENSION_721_V4_MAINNET,
  CLAIM_EXTENSION_1155_V4_MAINNET,
  CLAIM_EXTENSION_721_V5_MAINNET,
  CLAIM_EXTENSION_1155_V5_MAINNET,
  CLAIM_EXTENSION_721_V6,
  CLAIM_EXTENSION_1155_V6,
  CLAIM_EXTENSION_721_SIGNATURE_MINTING_V1,
  CLAIM_EXTENSION_1155_SIGNATURE_MINTING_V1,
  CLAIM_EXTENSION_721_V7,
  CLAIM_EXTENSION_1155_V7,
  CLAIM_UPDATABLE_MINT_FEE_721_V1,
  CLAIM_UPDATABLE_MINT_FEE_1155_V1,
} = claims;

const {
  Claim1155UpdatableMintFeeV1ABI,
  Claim721UpdatableMintFeeV1ABI,
  Claim1155V1ABI,
  Claim721V1ABI,
  Claim1155V2PayableABI,
  Claim721V2PayableABI,
  Claim1155V3AirdropABI,
  Claim721V3AirdropABI,
  Claim1155V4FeesABI,
  Claim721V4FeesABI,
  Claim1155V5OptimizationABI,
  Claim721V5OptimizationABI,
  Claim1155V7ABI,
  Claim721V7ABI,
  Claim1155SignatureV1ABI,
  Claim721SignatureV1ABI,
} = abis;

export const FEE_PER_MINT = BigNumber.from(500000000000000); // 0.0005 ETH * 1e18;
export const FEE_PER_MERKLE_MINT = BigNumber.from(690000000000000); // 0.00069 ETH * 1e18;

export const FEE_ADDRESS = '0xd58F2402D104df9C8A8667e24a3A5C79f8EC66aA';

export const MANIFOLD_FEE_EXPLANATION =
  'Manifold charges a minimal fee for items collected through our apps while creators retain 100% of the revenue. This fee supports ongoing maintenance and future development.';

export const PROCESSING_FEES_EXPLANATION =
  'Processing fees include Ethereum network expenses and card payment fees, helping to maintain a secure and efficient user experience. This includes vital components such as fraud protection, transaction monitoring, currency exchange risk management, and responsive customer support.';

export const ETHEREUM_NETWORK_NAMES: Record<number, string> = {
  1: 'ETHEREUM',
  3: 'ROPSTEN',
  10: 'OPTIMISM',
  8453: 'BASE',
  42: 'KOVAN',
  137: 'MATIC', // @WARN: this is wrong, it should say POLYGON but refacotring this could break something
  11155111: 'SEPOLIA',
};

export const EXTENSION_TRAITS: { [_: string]: string[] } = {
  [CLAIM_EXTENSION_721_V1_MAINNET]: [],
  [CLAIM_EXTENSION_1155_V1_MAINNET]: [],

  [CLAIM_EXTENSION_721_V2_MAINNET]: [],
  [CLAIM_EXTENSION_1155_V2_MAINNET]: [],

  [CLAIM_EXTENSION_721_V3_MAINNET]: ['delegateMint', 'delegateV1'],
  [CLAIM_EXTENSION_1155_V3_MAINNET]: ['delegateMint', 'delegateV1'],

  [CLAIM_EXTENSION_721_V4_MAINNET]: ['delegateMint', 'delegateV1', 'fee'],
  [CLAIM_EXTENSION_1155_V4_MAINNET]: ['delegateMint', 'delegateV1', 'fee'],

  [CLAIM_EXTENSION_721_V5_MAINNET]: ['delegateMint', 'delegateV1', 'fee', 'contractVersion'],
  [CLAIM_EXTENSION_1155_V5_MAINNET]: ['delegateMint', 'delegateV1', 'fee', 'contractVersion'],
  [CLAIM_EXTENSION_721_V6]: ['delegateMint', 'delegateV1', 'fee', 'contractVersion'],
  [CLAIM_EXTENSION_1155_V6]: ['delegateMint', 'delegateV1', 'fee', 'contractVersion'],
  '0x28a72d7e0f1fab1d78f3c4cdd7880c3b01b8a519': [
    'delegateMint',
    'delegateV1',
    'fee',
    'contractVersion',
  ],
  '0x79f2c893350de49c78cfc0b11622791901b326ef': [
    'delegateMint',
    'delegateV1',
    'fee',
    'contractVersion',
  ],
  [CLAIM_EXTENSION_721_SIGNATURE_MINTING_V1]: [
    'delegateMint',
    'delegateV1',
    'fee',
    'contractVersion',
    'signature',
  ],
  [CLAIM_EXTENSION_1155_SIGNATURE_MINTING_V1]: [
    'delegateMint',
    'delegateV1',
    'fee',
    'contractVersion',
    'signature',
  ],
  [CLAIM_EXTENSION_721_V7]: [
    'delegateMint',
    'delegateV1',
    'delegateV2',
    'fee',
    'contractVersion',
    'signature',
  ],
  [CLAIM_EXTENSION_1155_V7]: [
    'delegateMint',
    'delegateV1',
    'delegateV2',
    'fee',
    'contractVersion',
    'signature',
  ],
  [CLAIM_UPDATABLE_MINT_FEE_721_V1]: [
    'delegateMint',
    'delegateV1',
    'delegateV2',
    'fee',
    'contractVersion',
    'signature',
    'updateMintFee',
  ],
  [CLAIM_UPDATABLE_MINT_FEE_1155_V1]: [
    'delegateMint',
    'delegateV1',
    'delegateV2',
    'fee',
    'contractVersion',
    'signature',
    'updateMintFee',
  ],
};

export const EXTENSION_ABIS: { [_: string]: any[] } = {
  [CLAIM_EXTENSION_721_V1_MAINNET]: Claim721V1ABI,
  [CLAIM_EXTENSION_1155_V1_MAINNET]: Claim1155V1ABI,

  [CLAIM_EXTENSION_721_V2_MAINNET]: Claim721V2PayableABI,
  [CLAIM_EXTENSION_1155_V2_MAINNET]: Claim1155V2PayableABI,

  [CLAIM_EXTENSION_721_V3_MAINNET]: Claim721V3AirdropABI,
  [CLAIM_EXTENSION_1155_V3_MAINNET]: Claim1155V3AirdropABI,

  [CLAIM_EXTENSION_721_V4_MAINNET]: Claim721V4FeesABI,
  [CLAIM_EXTENSION_1155_V4_MAINNET]: Claim1155V4FeesABI,

  [CLAIM_EXTENSION_721_V5_MAINNET]: Claim721V5OptimizationABI,
  [CLAIM_EXTENSION_1155_V5_MAINNET]: Claim1155V5OptimizationABI,
  [CLAIM_EXTENSION_721_V6]: Claim721V5OptimizationABI,
  [CLAIM_EXTENSION_1155_V6]: Claim1155V5OptimizationABI,

  '0x28a72d7e0f1fab1d78f3c4cdd7880c3b01b8a519': Claim1155V5OptimizationABI, // OP Refund Shim
  '0x79f2c893350de49c78cfc0b11622791901b326ef': Claim1155V7ABI, // Polygon

  [CLAIM_EXTENSION_721_SIGNATURE_MINTING_V1]: Claim721SignatureV1ABI,
  [CLAIM_EXTENSION_1155_SIGNATURE_MINTING_V1]: Claim1155SignatureV1ABI,
  [CLAIM_EXTENSION_721_V7]: Claim721V7ABI,
  [CLAIM_EXTENSION_1155_V7]: Claim1155V7ABI,
  [CLAIM_UPDATABLE_MINT_FEE_721_V1]: Claim721UpdatableMintFeeV1ABI,
  [CLAIM_UPDATABLE_MINT_FEE_1155_V1]: Claim1155UpdatableMintFeeV1ABI,
};

export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000';

export const DELEGATION_REGISTRY_ADDRESS_V1 = '0x00000000000076a84fef008cdabe6409d2fe638b';
export const DELEGATION_REGISTRY_ADDRESS_V2 = '0x00000000000000447e69651d841bd8d104bed493';

export const SAVED_EMAIL_LOCAL_STORAGE_KEY = 'saved-email';
export const SAVED_RECIPIENT_ADDRESS_LOCAL_STORAGE_KEY = 'saved-recipient-address';
